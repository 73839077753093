import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { store } from "./app/store";
import { Authenticated } from "./components/Authentificated";
import { Loading } from "./components/Loading";
import { Unauthentificated } from "./components/Unauthentificated";
import { useAuth } from "@nikolausturnier/shared/src/hooks";
import { getTheme } from "@nikolausturnier/shared/src/theme";

export const App = () => {
  const { user } = useAuth();

  if (user !== undefined) {
    return (
      <Provider store={store}>
        <ThemeProvider theme={getTheme(user)}>
          <BrowserRouter>
            {user ? <Authenticated user={user} /> : <Unauthentificated />}
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  } else {
    return <Loading />;
  }
};
